
/* ==========================================================================
   Remodal's necessary styles
   ========================================================================== */

/* Hide scroll bar */

html.remodal-is-locked {
    overflow: hidden;
  
    touch-action: none;
  }
  
  /* Anti FOUC */
  
  .remodal,
  [data-remodal-id] {
    display: none;
  }
  
  /* Necessary styles of the overlay */
  
  .remodal-overlay {
    position: fixed;
    z-index: 9999;
    top: -5000px;
    right: -5000px;
    bottom: -5000px;
    left: -5000px;
  
    display: none;
  }
  
  /* Necessary styles of the wrapper */
  
  .remodal-wrapper {
    position: fixed;
    z-index: 10000;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  
    display: none;
    overflow: auto;
  
    text-align: center;
  
    -webkit-overflow-scrolling: touch;
  }
  
  .remodal-wrapper:after {
    display: inline-block;
  
    height: 100%;
    margin-left: -0.05em;
  
    content: "";
  }
  
  /* Fix iPad, iPhone glitches */
  
  .remodal-overlay,
  .remodal-wrapper {
    backface-visibility: hidden;
  }
  
  /* Necessary styles of the modal dialog */
  
  .remodal {
    position: relative;
  
    outline: none;
  
    text-size-adjust: 100%;
  }
  
  .remodal-is-initialized {
    /* Disable Anti-FOUC */
    display: inline-block;
  }
  