.button, button, input[type="button"], input[type="submit"] {
    @include buttonTextStyle();
    display:inline-flex;
    justify-content:center;
    align-items:center;
    cursor:pointer;
    box-shadow:none;
    min-height:$button-height;
    padding:$button-padding;
    border:$button-border-width $button-border-style $primary-button-border-color;
    border-radius: $button-border-radius;
    background-color:$primary-button-bg;
    color:$primary-button-color;
    transition:$color-transition, $bg-color-transition, $border-color-transition;

    &.small, &.small-button {
        min-height:$button-small-height;
        padding:$button-small-padding;
        font-size:$button-small-fsize;
        line-height:$button-small-lheight;
    }

    &.mini, &.mini-button {
        min-height:$button-mini-height;
        padding:$button-mini-padding;
        font-size:$button-mini-fsize;
        line-height:$button-mini-lheight;
        text-transform: $button-mini-text-transform;
    }

    &.secondary, &.secondary-button {
        color:$secondary-button-color;
        background:$secondary-button-bg;
        border-color:$secondary-button-border-color;
    }


    &.empty, &.empty-button {
        background:$white;
        border-color:$primary-button-border-color;
        color:$primary-button-bg;

        &.secondary, &.secondary-button {
            background:$white;
            color:$secondary-button-bg;
            border-color:$secondary-button-border-color;
        }
    }
}

label {
    font-size:12px;
    line-height:16px;
    margin-bottom:5px;
    display:inline-flex;

    &.required {
        &:after {
            content:'*';
            color:$red;
        }
    }
}

.form-content {
    .field-label {
        display:block;
        color:$primary-color;
        font-size:$field-label-fsize;
        line-height:$field-label-lheight;
        margin-bottom:$field-label-mbottom;
        font-weight: $field-label-fweight;
        letter-spacing: $field-label-lspacing;

        &.has-error {
            color:$error-color;
        }
    }

    .error-content {
        color:$error-color;
        font-size:$form-error-content-fsize;
        line-height: $form-error-content-lheight;
        font-weight:$form-error-content-fweight;
        letter-spacing: $form-error-content-lspacing;
        margin-top:$form-error-content-mtop;
    }

    .row {
        margin-bottom:$form-row-gap;
        font-size:0px;
        line-height:0px;

        &.checkbox-row {
            margin-bottom:5px;
        }

        &:last-of-type, &.without-margin {
            margin-bottom:0px;
        }
    }

    .more-col-row {
        display:flex;
        justify-content:space-between;
        align-items: flex-start;
        flex-wrap:wrap;

        .col {
            margin-top:$form-row-gap;
            
            &:not(.full-width) {
                width:calc(50% - (#{$form-column-gap} / 2));
            }
            
            &:nth-of-type(1), &:nth-of-type(2) {
                margin-top:0px;
            }
        }

        .break-row {
            width:100%;
        }
    }

    .button-row {
        margin-top:30px;
    }

    .depends-on-row {
        display:none;
        &:not(.without-padding) {
            padding-top:$form-row-gap;
        }
    }
}

.field, .form-control {
    appearance: none;
    outline:none;
    box-shadow:none;
    width:100%;
    border:$input-border-width $input-border-style $input-border-color;
    border-radius:$input-border-radius;
    padding:$input-padding;
    height:$input-height;
    @include inputFontText();
    color:$input-color;
    transition:$color-transition, $bg-color-transition, $border-color-transition;

    &.secondary, &.secondary-field {
        &:not(:invalid):not(.has-error) {
            border-color:$input-secondary-border-color;
        }
    }

    &.has-error {
        color:$input-error-color;
        background-color:$input-error-bg;
        border-color:$input-error-border-color;
    }

    &:focus {
        color:$input-focus-color;
        background-color:$input-focus-bg;
        border-color:$input-focus-border-color;
    }
}

textarea {
    &.field, &.form-control {
        min-height:115px;
        resize:none;
    }
}

select {
    appearance: none;
    outline:none;
    box-shadow:none;
    width:100%;
    display:block;
    border:$input-border-width $input-border-style $input-border-color;
    border-radius:$input-border-radius;
    padding:$select-padding;
    height:$input-height;
    @include inputFontText();
    color:$input-color;
    transition:$color-transition, $bg-color-transition, $border-color-transition;
    background:$select-bg;

    &:invalid, &.has-error {
        color:$input-error-color;
        background-color:$input-error-bg;
        border-color:$input-error-border-color;
    }

    &.secondary {
        &:not(:invalid):not(.has-error) {
            border-color: $input-secondary-border-color;
        }
    }
}

@-moz-document url-prefix() {
    select {
        text-indent: -2px;
    }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

@include placeholder {
    opacity:1;
    color:$input-placeholder-color;
}

input[type="checkbox"] {
    width:0px;
    height:0px;
    display:none;

    & + label {
        padding-left:#{$checkbox-dimension-size + $checkbox-side-padding};
        position:relative;
        display:inline-block;
        font-size:$checkbox-fsize;
        line-height:$checkbox-lheight;
        color:$checkbox-color;
        transition:$checkbox-label-transition;
        margin:0px;

        a {
            color:$checkbox-anchor-color;
        }

        &:before, &:after {
            content:'';
            width:$checkbox-dimension-size;
            height:$checkbox-dimension-size;
            position:absolute;
        }

        &:before {
            top:$checkbox-before-top-position;
            left:$checkbox-before-left-position;
            background:$checkbox-bg;
            border:$checkbox-border-width $checkbox-border-style $checkbox-border-color;
            border-radius:$checkbox-border-radius;
            transition:$checkbox-before-transition;
        }

        &:after {
            top:$checkbox-after-top-position;
            left:$checkbox-after-left-position;
            background:$checkbox-checked-bg;
            transition:$checkbox-after-transition;
            opacity:$checkbox-after-opacity;
            border-radius:$checkbox-border-radius;
        }

        &.secondary {
            &:before {
                border-color:$checkbox-secondary-border-color;
            }

            &:after {
                background:$checkbox-secondary-checked-bg;
            }
        }
    }

    &.small-checkbox + label {
        font-size:$checkbox-small-fsize;
        line-height:$checkbox-small-lheight;
        padding-left:#{$checkbox-small-dimension-size + $checkbox-small-side-padding};

        &:before, &:after {
            width:$checkbox-small-dimension-size;
            height:$checkbox-small-dimension-size;
            top:$checkbox-small-before-top-position;
        }

        &:after {
            background:$checkbox-small-checked-bg;
        }

        &.secondary {
            &:after {
                background:$checkbox-secondary-small-checked-bg;
            }
        }
    }

    &:checked + label {
        color:$checkbox-checked-color;

        &:after {
            opacity:$checkbox-after-checked-opacity;
        }
    }

    &.has-error {
        &:not(:checked) {
            & + label {
                &, &.secondary {
                    color:$error-color;

                    a {
                        color:inherit;
                    }
                }
            }
        }
    }
}

input[type="radio"] {
    width:0px;
    height:0px;
    display:none;

    &:not(.button-radio) {
        & + label {
            position:relative;
            margin-bottom:0px;
            font-size:$radio-fsize;
            line-height:$radio-lheight;
            padding-left:#{$radio-dimenson-size + $radio-side-padding};
            display:inline-block;
            transition:$radio-transition;

            &:before, &:after {
                content:'';
                position:absolute;
                border-radius:$radio-border-radius;
            }

            &:before {
                border:$radio-border-width $radio-border-style $radio-before-border-color;
                background:$radio-before-bg;
                transition:$radio-before-transition;
                width:$radio-dimenson-size;
                height:$radio-dimenson-size;
                top:$radio-before-top-position;
                left: $radio-before-left-position;
            }

            &:after {
                width:$radio-after-dimension-size;
                height:$radio-after-dimension-size;
                background:$radio-after-bg;
                top:$radio-after-top-position;
                left: $radio-after-left-position;
                opacity: $radio-after-opacity;
                transition:$radio-after-transition;
            }
        }

        &:checked + label {
            color:$radio-checked-color;
            &:before {
                background:$radio-checked-before-bg;
                border-color:$radio-checked-before-border-color;
            }
            &:after {
                background:$radio-checked-after-bg;
                opacity:$radio-after-checked-opacity;
            }
        }

        &.has-error {
            &:not(:checked) + label {
                &:before {
                    background-color:$radio-error-before-bg;
                    border-color:$radio-error-before-border-color;
                }
            }
        }
    }
}


@include forFirefox {
    input[type="checkbox"],
    input[type="radio"] {
        & + label {
            &:before, &:after {
                transform:translateY(1px);
            }
        }
    }
}

@include hoverStatements {
    .button, button, input[type="button"], input[type="submit"] {
        &:hover {
            color:$primary-button-hover-color;
            background:$primary-button-hover-bg;
            border-color:$primary-button-hover-border-color;

            &.secondary, &.secondary-button {
                color:$secondary-button-hover-color;
                background:$secondary-button-hover-bg;
                border-color:$secondary-button-hover-border-color;
            }

            &.empty, &.empty-button {
                color:$primary-button-color;
                background:$primary-button-bg;
                border-color:$primary-button-border-color;

                &.secondary, &.secondary-button {
                    color:$secondary-button-color;
                    background:$secondary-button-bg;
                    border-color:$secondary-button-border-color;
                }
            }
        }
    }

    input[type="checkbox"] {
        & + label {
            a {
                &:hover {
                    color:$checkbox-anchor-hover-color;
                }
            }
        }
    }
}

@include breakpoint(map-get($breakpoints, "mobile")) {
    .form-content {
        .row {
            margin-bottom:$form-mobile-row-gap;
        }

        .more-col-row {
            .col {
                margin-top:$form-mobile-row-gap;
                &:not(.full-width) {
                    width:100%;
                }

                &:nth-of-type(2) {
                    margin-top:$form-mobile-row-gap;
                }
            }
        }
    }
}