.calculator-container {
    text-align:left;

    .selectric-wrapper {
        height:56px;
    }

    .calculator-tabs {
        gap:0px 10px;

        .calculator-document-icon {
            min-width:16px;
        }

        .calculator-box-icon {
            min-width:20px;
        }

        .calculator-pallet-icon {
            min-width:25px;
        }

        .calculator-not-in-box-icon {
            min-width:21px;
        }

        .calculator-tab-item {
            display:flex;
            justify-content:center;
            align-items: flex-end;
            font-size:0px;
            line-height:0px;
            color:$white;
            height:67px;
            cursor:pointer;
            
            .inner {
                background:rgba(204, 204, 204, 0.6);
                border:0px solid $orange;
                padding:10px 24px 10px 24px;
                border-radius:2px 2px 0px 0px;
                height:59px;
                transition:$bg-color-transition;
            }

            .text {
                font-size:16px;
                line-height:18px;
                font-weight:$bold-fweight;
                transition:$color-transition;
            }

            .icon {
                margin-right:9px;
                transition:$color-transition;
            }

            &.active {
                color:$primary-color;
                
                .icon {
                    color:$orange;
                }
                
                .inner {
                    border-top-width:8px;
                    background:rgba(255, 255, 255, 0.86);
                    height:67px;
                }
            }
        }
    }

    .calculator-content {
        padding:30px 0px 30px 0px;

        > .close-btn {
            display:none;
            top:10px;
            right:10px;
        }
    }

    .calculator-inner {
        width:calc(100% - 80px);
        margin:auto;
        position:relative;

        .button {
            min-height:48px;
        }
    }

    .title {
        font-size:24px;
        line-height:32px;
        font-weight:$bold-fweight;
        margin-bottom:18px;
    }

    .add-row-link {
        text-decoration:none;

        .icon {
            margin-right:7px;
        }

        .text {
            font-size:18px;
            line-height:18px;
            letter-spacing:$secondary-lspacing;
            transform:translateY(1px);
        }
    }

    label {
        font-weight:$bold-fweight;
        margin-bottom:2px;
        display:block;
        letter-spacing: $secondary-lspacing;

        &.big-label {
            font-size:18px;
            line-height:20px;
        }
    }

    .address-content {
        margin-bottom:26px;

        select, .field {
            height:56px;
        }

        .address-box {
            width: calc((100% - 56px) / 2);
        }

        .arrow-box {
            width:56px;
            height:56px;
            align-self:flex-end;
        }

        .zip-outer {
            width:70px;
        }

        .country-outer {
            width:calc(100% - 80px);
        }
    }

    .packages-content {
        margin-bottom:25px;
    }

    .package-row {
        gap:10px;
        grid-template-columns: 195px repeat(4, calc((100% - 261px) / 4)) 22px;;
        margin-bottom:25px;
        
        &:last-of-type {
            margin-bottom:0px;
        }


        label {
            font-size:14px;
            line-height:16px;
        }

        .counter {
            width:36px;
            height:48px;
            display:flex;
            justify-content:center;
            align-items:center;
            font-weight:$bold-fweight;
            border-radius:2px;
            background:$lighter-blue;
            margin-right:10px;

            .inner {
                display:flex;
                justify-content:center;
                align-items:flex-end;

                .hash {
                    font-size:14px;
                    line-height:14px;
                }

                .number {
                    font-size:16px;
                    line-height:16px;
                }
            }
        }

        .counter-col {
            display:flex;
            align-items:flex-end;
            flex-wrap:wrap;

            > label {
                width:100%;
            }

            .field-container {
                width:calc(100% - 46px);
                max-width:120px;
            }
        }

        .field-container {
            position:relative;
            
            label {
                font-weight:$normal-fweight;
                padding:0px 0px 0px 0px;
                transform:translateY(1px);
                position:absolute;
                height:16px;
                top:0px;
                bottom:0px;
                right:10px;
                margin:auto;
            }

            .field {
                padding-right:35px;
            }
        }

        .delete-col {
            height:48px;
            align-self:end;
            width:16px;

            a {
                position:relative;
                display:block;
                width:16px;
                height:21px;
                cursor:pointe;

                .icon {
                    width:100%;
                    height:100%;
                }

                &:before {
                    content:'';
                    position:absolute;
                    display:block;
                    top:0px;
                    bottom:0px;
                    width:calc(100% + 8px);
                    height:calc(100% + 8px);
                    transform:translate(-4px, -4px);
                }
            }
        }
    }

    .submit-row {
        margin-top:10px;

        .info {
            padding-right:15px;

            .icon {
                min-width:20px;
                margin-right:6px;
            }

            .text {
                font-size:14px;
                line-height:20px;
            }
        }
    }

    .single-form-submit-row {
        position:absolute;
        right:0px;
        bottom:49px;
    }

    .field-container {
        position:relative;

        .error-content {
            position:absolute;
            top:calc(100% + 2px);
            color:$error-color;
            font-size:12px;
            line-height:16px;
            font-weight:$semi-fweight;
            white-space: nowrap;
        }
    }

    .calculator-panel {
        margin-bottom:25px;
    }

    .calculator-panel-inner {
        padding:14px 15px 14px 10px;
        border-radius:2px;
        background:$light-gray;
        width:100%;
        max-width:370px;

        .send-button {
            display:none;
        }

        .left-content {
            padding-right:15px;
        }

        .icon {
            color:$blue;
        }

        .package-infos {
            font-size:0px;
            line-height:0px;
            padding-left:10px;

            .label {
                font-size:12px;
                line-height:16px;
            }

            .row {
                margin-bottom:5px;

                &:last-of-type {
                    margin-bottom:0px;
                }
            }

            .info {
                font-size:14px;
                line-height:20px;
                display:flex;
                align-items: center;

                &:after {
                    content:'';
                    width:1px;
                    height:20px;
                    background:$dark-gray;
                    margin:0px 9px 0px 9px;
                }

                &:last-of-type {
                    &:after {
                        display:none;
                    }
                }
            }

            .infos {
                &.mobile-infos {
                    display:none;
                }
            }
        }
    }
}

@include hoverStatements {
    .calculator-container {
        .add-row-link {
            &:hover {
                .text {
                    text-decoration: underline;
                }
            }
        }

        .delete-col {
            a {
                &:hover {
                    color:$orange;
                }
            }
        }
    }
}

@include breakpoint(1100px) {
    .calculator-container {
        .single-form-submit-row {
            bottom:0px;
            transform:translate(20px, 10px);
        }
    }
}

@include below-lying-tablet {
    .calculator-container {
        .calculator-tabs {
            .calculator-tab-item {
                height:61px;

                .icon {
                    display:none;
                }

                .inner {
                    padding:7px 15px 7px 15px;
                    height:53px;
                }

                .text {
                    text-align:center;
                }

                &.active {
                    .inner {
                        height:61px;
                    }
                }
            }
        }

        .calculator-inner {
            width:calc(100% - 60px);
        }

        .submit-row {
            flex-wrap:wrap;
            flex-direction: column;

            .info {
                width:100%;
                padding-right:0px;
                margin-bottom:10px;
            }

            .button {
                align-self:flex-end;
            }
        }

        .package-row {
            grid-template-columns: 150px repeat(4, calc((100% - 222px) / 4)) 22px;
        }

        .single-form-submit-row {
            bottom:0px;
            transform:translate(10px, 10px);
        }

        &.has-panel {
            margin-bottom:0px!important;

            .calculator-panel {
                z-index:100;
                position:fixed;
                bottom:0px;
                left:0px;
                right:0px;
                height:54px;
                margin:0px;
                transition:$transform-transition;

                &.inactive {
                    transform:translateY(calc(100% + 10px));
                }

                .calculator-panel-inner {
                    width:100%;
                    max-width:none;
                    background: #F7F7F7;
                    box-shadow: 0 -1px 8px 0 rgba(0,0,0,0.20);
                    padding:14px #{map-get($sidePaddings, "tablet")};
                }
            }

            .calculator-content {
                position:fixed;
                border-radius:5px 5px 0px 0px;
                display:block!important;
                width:100%;
                overflow:hidden;
                bottom:0px;
                left:0px;
                right:0px;
                z-index:99;
                transition:$transform-transition;
                transform:translateY(calc(100% + 50px));
                background: #F7F7F7;
                box-shadow: 0 -1px 40px 0 rgba(0,0,0,0.36);
                height:auto;
                max-height:calc(78vh - 100px);
                padding-bottom:60px;
                
                > .close-btn {
                    display:block;
                }

                .calculator-inner {
                    overflow-y:auto;
                    overflow-x:hidden;
                    height:100%;
                    max-height:calc(78vh - 190px);

                    &:after {
                        content:'';
                        height:40px;
                        width:1px;
                        display:block;
                        min-width:1px;
                        min-height:40px;
                    }
                }

                .single-form-submit-row {
                    display:none;
                }

                .packages-content, .address-content {
                    max-width:none;
                }
            }

            .calculator-panel {
                .calculator-panel-inner {
                    .package-infos {
                        .infos {
                            &.desktop-infos {
                                display:none;
                            }

                            &.mobile-infos {
                                display:block;
                                font-size:12px;
                                line-height:16px;
                            }
                        }
                    }
                }
            }

            &.opened-calculator {
                .calculator-content {
                    transform:translateY(0px);
                }

                .calculator-panel-inner {
                    .panel-button {
                        display:none;
                    }

                    .send-button {
                        display:flex;
                    }
                }
            }
        }
    }

    .opened-fix-header {
        .calculator-container {
            &.has-panel {
                .calculator-content {
                    max-height:calc(78vh - 50px);

                    .calculator-inner {
                        max-height:calc(78vh - 140px);
                    }
                }
            }
        }
    }
}

@include below-standing-tablet {
    .calculator-container {
        .package-row {
            grid-template-columns: repeat(2, calc(50% - 5px));
            gap:20px 10px;
            margin-bottom:20px;
            padding-bottom:25px;
            border-bottom:1px solid $gray;

            &:last-of-type {
                border-bottom:0px;
                margin-bottom:0px;
                padding-bottom:0px;
            }

            .counter-col {
                .field-container {
                    max-width:none;
                }
            }

            .delete-col {
                justify-content:flex-end;
                width:100%;
            }
        }

        .submit-row {
            margin-top:25px;

            .info {
                margin-bottom:15px;
            }

            .button {
                align-self:center;
                width:100%;
                max-width:400px;
            }
        }

        .address-content {
            flex-wrap:wrap;

            .arrow-box {
                display:none;
            }

            .address-box {
                width:100%;
                margin-bottom:20px;
                
                &:last-of-type {
                    margin-bottom:0px;
                }
            }

            .zip-outer {
                width:80px;
            }

            .country-outer {
                width:calc(100% - 90px);
            }
        }
    }
}

@include mobile {
    .calculator-container {
        .title {
            margin-bottom:14px;
            font-size:20px;
        }

        .calculator-content {
            padding-bottom:40px;
        }

        .selectric-wrapper, .field, .address-content .field {
            height:48px;
        }

        label.big-label {
            font-size:14px;
            line-height:16px;
        }

        .calculator-tabs {
            justify-content:flex-start;
            overflow-y:hidden;
            overflow-x:auto;

            .calculator-tab-item {
                height:52px;
                
                .icon {
                    display:none;
                }

                .inner {
                    padding:7px 10px 7px 10px;
                    height:44px;
                }

                .text {
                    text-align:center;
                    font-size:12px;
                    line-height:14px;
                }

                &.active {
                    .inner {
                        height:52px;
                    }
                }
            }
        }

        .calculator-inner {
            width:calc(100% - (#{map-get($sidePaddings, "mobile")} * 2));
        }

        .submit-row {
            .button {
                max-width:none;
            }
        }

        .package-row {
            .delete-col {
                a {
                    width:32px;
                    height:38px;
                    padding:5px;
                    transform: translateX(5px);
                }
            }
        }
    }
}