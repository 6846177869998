.search-form {
    width:180px;
    height:100%;
    margin-right:20px;
    background:#dddddd;
    display:flex;
    align-items:center;
    padding-right:15px;

    .field {
        background:transparent;
        border:none;
        height:100%;
        padding:8px 0px 8px 15px;
        margin-right:15px;
        min-height:auto;
        font-size:12px;
        line-height:16px;
        border-radius:0px;
        color:$dark-gray;
        font-weight:$semi-fweight;

        &:focus {
            border:none;
            background:transparent;
        }

        &:-internal-autofill-selected {
            background:transparent;
        }
    }

    .search-button {
        min-height:auto;
        padding:0px;
        background:transparent;
        border:none;
        color:$dark-gray;
        width:18px;
        height:17px;
        min-width:18px;

        .icon {
            width:100%;
            height:100%;
        }
    }
}