@import './core/selectric.scss';

.selectric-wrapper {
    height:48px;

    .selectric {
        height:100%;
        background:$white;
        border-color:$input-border-color;
        transition:$border-color-transition;
        border-radius:$input-border-radius;

        .label {
            height:100%;
            display:flex;
            align-items:center;
            font-size:0px;
            line-height:0px;
            color:$primary-color;
            margin: 0px 40px 0px 14px;

            .text {
                font-size:$input-fsize;
                line-height:$input-lheight;
                letter-spacing: $secondary-lspacing;
            }
        }

        .button {
            font-size:0px;
            line-height:0px;
            border:none;
            width:40px;
            height:100%;
            display:flex;
            justify-content:center;
            align-items:center;
            padding:5px;
            background:$white;

            &:after {
                border:none;
                position:relative;
                width:10px;
                height:6px;
                background:url('../img/svg/icons/select-arrow.svg') no-repeat center center / cover;
            }
        }
    }

    &.selectric-open {
        z-index:10;
        .selectric {
            border-color:$primary-color;
            border-radius:$input-border-radius $input-border-radius 0px 0px;
        }
    }

    .country-select-item {
        display:flex;
        align-items:center;

        .flag {
            margin-right:10px;
            width:18px;
            height:18px;
            border-radius:50%;
            overflow:hidden;
            min-width:18px;
            min-height:18px;
        }
    }

    &.selectric-above {
        .selectric-items {
            border-top:1px solid $primary-color;
            border-radius:$input-border-radius $input-border-radius 0px 0px;
            border-bottom:none;
        }
    
        &.selectric-open {
            .selectric {
                border-radius:0px 0px $input-border-radius $input-border-radius;
            }
        }
    }
}

.selectric-items {
    background:$white;
    border:1px solid $primary-color;
    border-top:none;
    border-radius:0px 0px $input-border-radius $input-border-radius;

    li {
        background:$white;
        color:$primary-color!important;
        font-size:14px;
        line-height:18px;
        padding:12px 10px 12px 10px;

        &:hover {
            background:$light-gray;
        }

        &.highlighted {
            background:$light-blue;
        }
    }

    .selectric-scroll {
        ul {
            margin:0px;
            padding:0px;

            li {
                margin:0px;
                
                &:before {
                    display:none;
                }
            }
        }
    }
}

