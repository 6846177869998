@import './search-box.scss';

.main-header {
    position:relative;
    margin-bottom:30px;
    z-index:100;
    padding-bottom:104px;

    .fix-content {
        position:absolute;
        bottom:0px;
        left:0px;
        right:0px;
    }

    .mobile-menu-btn, .mobile-profile-btn {
        display:none;
    }

    &.opened-fix-header {
        .fix-content {
            height:120px;
            position:fixed;
            top:-120px;
            left:0px;
            right:0px;
            z-index:100;
            transition:$transform-transition;
            transition-delay:100ms;
            transform:translateY(120px);
        }
    }

    a {
        text-decoration:none;
    }

    .hello-bar {
        padding:10px 0px 10px 0px;
        position:relative;

        .close-btn {
            width:20px;
            height:20px;
            top:0px;
            right:10px;
            bottom:0px;
            margin:auto;
            display:block;
            color:$white;

            .icon {
                width:100%;
                height:100%;
            }
        }

        .inner-content {
            gap:15px 25px;
        }

        .button.mini-button {
            min-height:20px;
            font-size:10px;
            background:rgba(255, 255, 255, 0.3);
            padding:4px 5px 4px 5px;
            border-color:transparent;
            color:$white;
            text-transform: none;
            letter-spacing:$secondary-lspacing;

            .icon {
                min-width:4px;
                margin-left:2.5px;
            }
        }
    }

    .profile-content {
        background:#f0f0f0!important;
        
        .inner-content {
            height:34px;
        }

        a {
            color:$dark-gray;

            .icon {
                margin-right:5px;
            }

            .text {
                display:block;
                font-size:12px;
                line-height:16px;
            }
        }

        .item {
            &:after {
                content:'';
                width:1px;
                min-width:1px;
                height:10px;
                display:block;
                background:$dark-gray;
                margin:auto 10px auto 10px;
            }

            &:last-of-type {
                &:after {
                    display:none;
                }
            }
        }
    }

    .menu-content {
        box-shadow: 0 5px 9px 0 rgba(71,71,75,0.08);
        background:$white;

        > .inner-content {
            height:70px;
        }

        .logo-content {
            transform:translate(-16px, -4px);
            position:relative;
            width:201px;

            img {
                position:relative;
                z-index:2;
            }

            .arrow-outer {
                filter:drop-shadow(0px 7px 6px rgba(71, 71, 75, 0.1));
                display:none;
                display:block;
                position:absolute;
                left:12px;
                bottom:0px;
                z-index:1;
                
                .arrow {
                    clip-path: polygon(0% 0%, 100% 0%, 50% 100%, 0% 0%);
                    background:$white;
                    display:block;
                    height:12px;
                    width:86px; 
                }
            }
        }
    }

    .main-menu {
        height:100%;
        ul {
            height:100%;
        }
        li {
            height:100%;
            display:flex;
            align-items:center;
            margin-right:34px;
            position:relative;

            &:last-of-type {
                margin-right:0px;
            }
        }

        .menu-list-item {
            > a:not(.button) {
                .icon {
                    color:$blue;
                    min-width:8px;
                    margin-left:10px;
                    transition:$transform-transition;
                }
            }
        }
    }

    .submenu {
        position:absolute;
        background:$white;
        padding:30px;
        border-radius:2px;
        box-shadow: 0 0 14px 0 rgba(71,71,75,0.10);
        width:234px;
        left:50%;
        top:calc(100% + 8px);
        transform:translateX(-50%);
        font-size:16px;
        line-height:20px;
        letter-spacing: $secondary-lspacing;
        display:block;
        opacity:0;
        visibility:hidden;
        transition:$opacity-transition;
        transition-delay: 30ms;

        &:before {
            content:'';
            width:0px;
            height:0px;
            left:0px;
            right:0px;
            bottom:100%;
            margin:auto;
            position:absolute;
            display:block;
            border-style:solid;
            border-width:0px 6px 7px 6px;
            border-color:transparent transparent $white transparent;
        }

        a {
            font-weight:$normal-fweight;
            color:$primary-color;
            text-decoration:none;
            position:relative;
        }

        .row {
            margin-bottom:20px;

            &:last-of-type {
                margin-bottom:0px;
            }
        }
    }
}

@include hoverStatements {
    .main-header {
        .hello-bar {
            .button.mini-button {
                &:hover {
                    background:$white;
                    border-color:$white;
                    color:$purple;
                }
            }

            .close-btn {
                &:hover {
                    opacity:1;
                }
            }
        }

        .profile-content {
            a {
                &:hover {
                    color:$primary-color;
                }
            }
        }

        .main-menu {
            li {
                > a:not(.button) {
                    &:hover {
                        color:$blue;
                    }
                }
                &.has-submenu {
                    &:hover {
                        > a {
                            color:$blue;

                            .icon {
                                transform:rotate(180deg);
                            }

                        }

                        .submenu {
                            visibility: visible;
                            opacity:1;

                            &:after {
                                content:'';
                                height:10px;
                                width:100%;
                                position:absolute;
                                left:0px;
                                bottom:100%;
                            }
                        }
                    }
                }
            }
        }

        .submenu {
            a {
                &:hover {
                    color:$blue;
                }
            }
        }
    }
}

@include showMobileMenu {
    .main-header {
        padding-bottom:94px;

        &.opened-fix-header {
            .fix-content {
                height:110px;
                top:-110px;
                transform:translateY(110px);
            }
        }

        .menu-content {
            box-shadow: 0 5px 12px 4px rgba(71,71,75,0.08);

            > .inner-content {
                height:60px;
            }

            .logo-content {
                position:absolute;
                top:0px;
                left:0px;
                right:0px;
                bottom:0px;
                margin:auto;
                width:160px;
                transform:translateY(-10px);
    
                img {
                    max-height:none;
                }

                .arrow-outer {
                    display:none;
                }
            }
        }

        .mobile-menu-btn {
            padding:5px;
            width:36px;
            height:31px;
            display:block;
            transform:translateX(-5px);
            color:$blue;
            cursor:pointer;

            .icon {
                width:100%;
                height:100%;
            }
        }

        .main-menu {
            li {
                &.site-item {
                    display:none;
                }
            }
        }   
    }
}

@include below-lying-tablet {
    .main-header {
        padding-bottom:86px;

        &.opened-fix-header {
            .fix-content {
                height:102px;
                top:-102px;
                transform:translateY(102px);
            }
        }

        .profile-content {
            .search-form {
                display:none;
            }
        }

        .menu-content {
            > .inner-content {
                height:52px;
            }
            
            .logo-content {
                width:130px;
                transform:translateY(-5px);
            }

            .tracking-button {
                min-height:32px;
                padding:6px 10px 6px 10px;
            }
        }        
    }
}

@include mobile {
    .main-header {
        padding-bottom:48px;

        &.opened-fix-header {
            .fix-content {
                height:64px;
                top:-64px;
                transform:translateY(64px);
            }
        }

        .hello-bar {
            .inner-content {
                display:flex;
                align-items:center;
                justify-content:flex-start;
                gap:0px;
                padding-right:50px;

                .button {
                    width:64px;
                    min-width:64px;
                }

                .text-content {
                    width:calc(100% - 64px);
                    padding-right:15px;
                    text-align:left;
                    font-size:14px;
                    line-height:16px;
                }
            }
        }

        .profile-content {
            display:none;
        }

        .menu-content {
            > .inner-content {
                height:48px;
            }

            .logo-content {
                width:120px;
                transform:translateY(-5px);
            }

            .main-menu {
                display:none;
            }
        }

        .mobile-menu-btn {
            width:32px;
            height:27px;
        }
    }
}