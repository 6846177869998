@import './core/remodal-default-theme.scss';
@import './core/remodal.scss';

html.remodal-is-locked {
    body {
        overflow:hidden;
        touch-action:none;
    }
}

.remodal {
    background: $white;
    box-shadow: 0 0 15px 0 rgba(0,0,0,0.08);
    border-radius: 2px;
    padding:40px 50px 55px 50px;
    text-align:left;

    .close-btn {
        top:15px;
        right:15px;
    }

    .modal-title {
        margin-bottom:35px;
    }

    &.size-big {
        max-width:840px;
    }

    &.size-small {
        max-width:390px;
        padding:40px 30px 40px 30px;

        .modal-title {
            margin-bottom:25px;
        }
    }
}

.remodal-overlay {
    background:rgba(57, 57, 57, 0.34);
}

.login-remodal {
    .form-content {
        margin-top:40px;
    }

    .password-row {
        margin-bottom:5px;
    }
}


@include below-lying-tablet {
    .remodal {
        padding:40px 35px 40px 35px;
    }
}

@include mobile {
    .remodal {
        &, &.size-small, &.size-big {
            padding:30px 15px 25px 15px;
        }
    }

    .login-remodal {
        .form-content {
            margin-top:20px;
        }
    }
}