@import './core/notyf.scss';

.notyf {
    .notyf__wrapper {
        padding:15px;
    }

    .notyf__toast {
        &.notyf--alert {
            background:$lighter-red;
        }
    }
}