.icon {
    display:block;
    font-size:0px;
    line-height:0px;
}

.profile-icon {
    width:18px;
    height:18px;
}

.tracking-icon {
    width:18px;
    height:19px;
}

.facebook-icon {
    width:21px;
    height:21px;
}

.twitter-icon {
    width:21px;
    height:18px;
}

.pwstudio-logo {
    width:16px;
    height:18px;
}

.calculator-document-icon {
    width: 16px;
    height: 21px;
}

.calculator-box-icon {
    width: 20px;
    height: 23px;
}

.calculator-pallet-icon {
    width: 25px;
    height: 19px;    
}

.calculator-not-in-box-icon {
    width: 21px;
    height: 21px;
}

.trash-icon {
    width:22px;
    height:28px;
}

.package-tick-icon {
    width:17px;
    height:18px;
}

.bordered-info-icon {
    width:20px;
    height:21px;
}

.decoration-arrow-1 {
    width:129px;
    height:32px;
}

.decoration-arrow-2 {
    width:99px;
    height:60px;
}

.decoration-arrow-3 {
    width:129px;
    height:44px;
}

.choose-us-icon-1 {
    width:43px;
    height:26px;
}

.choose-us-icon-2 {
    width:37px;
    height:35px;
}

.choose-us-icon-3 {
    width:47px;
    height:44px;
}

.choose-us-icon-4 {
    width:39px;
    height:44px;
}

.calculator-right-arrow {
    width:24px;
    height:9px;
}

.arrow-right {
    width:4px;
    height:8px;
}

.tick-icon {
    width:10px;
    height:8px;
}

.printer-required-icon, 
.printer-not-required-icon {
    width:29px;
    height:28px;
}

.cod-available-icon {
    width:31px;
    height:24px;
}

.transit-time-icon {
    width:29px;
    height:25px;
}

.package-pick-up-icon {
    width:22px;
    height:22px;
}

.info-icon {
    width: 22px;
    height: 22px;
}

.close-icon {
    width:12px;
    height:12px;
}

.arrow-left {
    width:7px;
    height:10px;
}

.arrow-down {
    width:8px;
    height:5px;
}

.search-icon {
    width:18px;
    height:17px;
}

.mobile-menu-icon {
    width:22px;
    height:17px;
}

.eye-icon {
    width:22px;
    height:15px;
}

.pdf-icon {
    width:16px;
    height:20px;
}

.pager-arrow-left,
.pager-arrow-right {
    width:6px;
    height:9px;
}