@keyframes fadeinup{
  0%{
    opacity: 0;
    bottom: -15px;
    max-height: 0;
    max-width: 0;
    margin-top: 0;
  }
  30%{
    opacity: 0.8;
    bottom: -3px;
  }
  100%{
    opacity: 1;
    bottom: 0;
    max-height: 200px;
    margin-top: 12px;
    max-width: 400px;
  }
}

@keyframes fadeoutdown{
  0%{
    opacity: 1;
    bottom: 0;
  }
  30%{
    opacity: 0.2;
    bottom: -3px;
  }
  100%{
    opacity: 0;
    bottom: -15px;
  }
}

@keyframes appear{
  0%{
    opacity: 0;
  }
  30%{
    opacity: 0.5;
  }
  100%{
    opacity: 1;
  }
}

@keyframes disappear{
  0%{
    opacity: 1;
  }
  30%{
    opacity: 0.1;
  }
  100%{
    opacity: 0;
  }
}

.notyf__icon--alert, .notyf__icon--confirm{
    margin: 0 auto;
    position: relative;
    width:34px;
    height:34px;
    display:block;
    font-size:0px;
    line-height:0px;
    border-radius:0px;
}

.notyf__icon--alert{
  background: url('../img/svg/icons/error-icon.svg') no-repeat center center / cover;

    &:after, &:before{
      display:none;
    }
}

.notyf__icon--confirm{
    background: url('../img/svg/icons/success-icon.svg') no-repeat center center / cover;
    &:after, &:before{
      display:none;
    }
}

.notyf__toast{
    display: block;
    overflow: hidden;
    animation: fadeinup 0.3s forwards;
    position: relative;
    border-radius: $border-radius;
    
    &.notyf--alert{
      background: $notyf-error-bg;
      color:$notyf-error-color;
      border:1px solid $notyf-error-border-color;
    }

    &.notyf--confirm{
      background: $notyf-success-bg;
      color:$notyf-success-color;
      border:1px solid $notyf-success-border-color;
    }

    &.notyf--disappear{
      animation: fadeoutdown 0.3s 1 forwards;
      animation-delay: 0.25s;
      .notyf__message{
        opacity: 1;
        animation: fadeoutdown 0.3s 1 forwards;
        animation-delay: 0.1s;
      }
      .notyf__icon{
        opacity: 1;
        animation: disappear 0.3s 1 forwards;
      }
    }
}

.notyf__wrapper{
    display: table;
    width: 100%;
    padding:$notyf-padding;
}

.notyf__icon{
    display: table-cell;
    width: $notyf-icon-width;
    text-align: center;
    vertical-align: middle;
    font-size: 0px;
    line-height:0px;
    opacity: 0;
    animation: appear 0.5s forwards;
    animation-delay: 0.25s;
}

.notyf__message{
    display: table-cell;
    width: calc(100% - #{$notyf-icon-width});
    width:100%;
    padding-left:16px;
    vertical-align: middle;
    position: relative;
    font-size:$notyf-fsize;
    line-height:$notyf-lheight;
    letter-spacing: $notyf-lspacing;
    font-style: $notyf-font-style;
    opacity: 0;
    animation: fadeinup 0.3s forwards;
    animation-delay: 0.15s;
}

.notyf{
  position: fixed;
  bottom: $notyf-bottom;
  right: $notyf-right;
  width: $notyf-width;
  color: $primary-color;
  z-index: 99999999;
}

/* Small screens */
@media only screen and (max-width: 736px){
  .notyf__container{
    width: 90%;
    margin: 0 auto;
    display: block;
    right: 0;
    left: 0;
  }
}

@include mobile {
  .notyf {
    bottom:$notyf-mobile-bottom;
    right:$notyf-mobile-right;
    width:$notyf-mobile-width;
  }

  .notyf__toast {
    width:calc(100% - 20px)!important;
    max-width:100%!important;
    margin:auto;
  }

  .notyf__message {
    font-size:$notyf-mobile-fsize;
    line-height: $notyf-mobile-lheight;
    letter-spacing: $notyf-mobile-lspacing;
  }
}
