.light-gray-bg {
    background-color:$light-gray;
}

.color-light-gray {
    color:$light-gray;
}

.gray-bg {
    background-color:$gray;
}

.color-gray {
    color:$gray;
}

.color-dark-gray {
    color:$dark-gray;
}

.purple-bg {
    background-color:$purple;
}

.blue-bg {
    background-color:$blue;
}

.color-blue {
    color:$blue;
}

.light-blue-bg {
    background:$light-blue;
}

.color-light-blue {
    color:$light-blue;
}

.orange-bg {
    background:$orange;
}

.color-orange {
    color:$orange;
}