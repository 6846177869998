body.opened-mobile-menu {
    overflow: hidden;

    .mobile-menu-overlay {
        opacity:1;
        visibility:visible
    }
}

.mobile-menu-overlay {
    background:rgba(57, 57, 57, 0.34);
    width: 100%;
    top: 0px;
    right: 0px;
    left: 0px;
    bottom: 0px;
    position: fixed;
    z-index: 108;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.25s ease;
    transition-delay: 150ms;
}

.mobile-menu-content {
    display: block;
    z-index: 109;
    top: 0px;
    left: 0px;
    bottom: 0px;
    width: $mobile-menu-width;
    background: $mobile-menu-bg;
    color: $mobile-menu-primary-color;
    transition:$transform-transition;
    padding: 0px 0px 50px 0px;
    box-sizing: border-box;
    position: fixed;
    overflow: hidden;
    transform:translateX(#{$mobile-menu-width * -1});

    .move {
        transition: none !important;
    }

    &.opened {
        visibility:visible;
        opacity:1;
        transform: translateX(0px);
    }

    &.animated {
        z-index:109;
    }

    a {
        text-decoration:none;
        color:$mobile-menu-primary-color;
        font-weight:$normal-fweight;
    }

    .close-btn {
        width: 24px;
        height: 24px;
        top: 15px;
        right: 25px;
        color: $mobile-menu-secondary-color;
        z-index: 5;
        opacity:1;
    }

    .mobile-menu-group {
        width:calc(100% - 60px);
        margin-left:auto;
        margin-right:auto;

        &:first-of-type {
            margin-top:52px;
        }

        &.profile-group {
            margin-top:16px;
            margin-bottom:16px;
        }

        &.sites-group {
            margin-top:20px;
        }

        &.tracking-group {
            margin-top:25px;
        }
    }

    .separator {
        width:calc(100% - 50px);
        margin-left:auto;
        margin-right:auto;
        background:$mobile-menu-border-color;
        height:2px;
    }

    .search-form {
        margin:0px auto 15px auto;
        width:100%;
        background:$light-gray;
    }


    .mobile-menu-inner {
        display: block;
        width: 100%;
        position:relative;
        z-index:2;
        height: 100vh;
        overflow-x: hidden;
        overflow-y: auto;
        font-size:16px;
        line-height:20px;
        letter-spacing:$secondary-lspacing;

        &:after {
            content:'';
            height:50px;
            min-height:50px;
            display:block;
            width:100%;
        }
    }

    .first-level-item {
        padding:5px 0px 5px 0px;
        font-weight:$bold-fweight;

        .text {
            font-size:16px;
            line-height:20px;
            padding-right:15px;
        }

        .icon {
            color:$blue;
            transform:rotate(-90deg);
            min-width:8px;
            min-height:5px;
        }
    }

    .profile-item {
        color:$dark-gray;
        font-size:0px;
        line-height:0px;
        padding:5px 0px 5px 0px;
        
        .profile-icon {
            width:18px;
            height:18px;
            min-width:18px;
            margin-right:5px;
        }

        .text {
            font-size:16px;
            line-height:16px;
            font-weight:$bold-fweight;
        }
    }

    .profile-parent-item {
        .profile-icon {
            transform:none;
        }

        .text {
            font-weight:$normal-fweight;
            color:$primary-color;
            font-size:14px;
            line-height:18px;
            transform: translateY(2px);
            transition:$color-transition;
        }
    }

    .menu-item-outer {
        margin-bottom:10px;

        &:last-of-type {
            margin-bottom:0px;
        }

        &.opened-submenu {
            .mobile-submenu {
                transform: translateX(0px);
            }
        }
    }

    .tracking-button {
        width:100%;

        .text {
            font-weight:$bold-fweight;
        }
    }

    .social-group {
        margin-top:40px;

        .social-item {
            margin-right:15px;
            color:$mobile-menu-secondary-color;
    
            &:last-of-type {
                margin-right:0px;
            }
        }
    }

    .mobile-submenu {
        transition:$transform-transition;
        transform: translateX(#{$mobile-menu-width * -1});
        width: $mobile-menu-width;
        height: 100vh;
        position: fixed;
        background: $mobile-menu-bg;
        left: 0px;
        top: 0px;
        z-index: 10;
        overflow:hidden;
        padding-top: 52px;     

        .separator {
            width:calc(100% + 10px);
            margin-bottom:10px;
            transform:translateX(-5px);
        }

        .mobile-menu-header {
            height:52px;
            position:absolute;
            top:0px;
            left:0px;
            right:0px;
            display:flex;
            align-items:center;
            padding:0px 0px 0px 30px;
            color:$mobile-menu-secondary-color;

            .text {
                font-weight:$bold-fweight;
                padding-left:5px;
            }

            &:after {
                content:'';
                left:0px;
                right:0px;
                bottom:0px;
                margin:auto;
                position:absolute;
                display:block;
                width:calc(100% - 50px);
                height:2px;
                background:$mobile-menu-border-color;

            }
        }


        .submenu-inner {
            overflow-x:hidden;
            overflow-y:auto;
            height:100%;
            padding:15px 30px 20px 30px;

            .submenu-link {
                display:inline-flex;
                padding:5px 0px 5px 0px;
            }

            .submenu-row {
                margin-bottom:10px;

                &:last-of-type {
                    margin-bottom:0px;
                }
            }
        }
    }

    .logout-link {
        color:$dark-gray;
    }
}

@include hoverStatements {
    .mobile-menu-content {
        a {
            &:hover {
                text-decoration: none;
                color:$blue;

                &.profile-item, &.logout-link {
                    color:$primary-color;
                }

                &.profile-parent-item {
                    .text {
                        color:$blue;
                    }
                }
            }
        }
    }
}

@include mobile {
    .mobile-menu-content {
        width:100vw;
        transform:translateX(-110%);

        .mobile-submenu {
            width:100vw;
            transform:translateX(-110%);
        }
    }
}