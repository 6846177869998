* {
    box-sizing:border-box;
    margin:0px;
    padding:0px;
    
    &:before, &:after {
        box-sizing:border-box;
    }

    p {
        &:last-of-type {
            margin-bottom:0px;
        }
    }
}

body {
    display:block;
    background:$white;
    @include bodyFont();

    -webkit-overflow-scrolling: touch;
    text-rendering: auto;

    -webkit-font-smoothing: antialiased;
    -moz-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    overflow-y: auto;
    overflow-x: hidden;

    &.noscroll {
        overflow-y:hidden;
    }
}

a {
    font-weight:$anchor-fweight;
    transition:$color-transition;
    color:$primary-anchor-color;
    text-decoration: $anchor-default-text-decoration;

    &.secondary {
        color:$secondary-anchor-color;
    }
}

p {
    margin-bottom:$paragraph-bottom-margin;
    
    &:last-of-type, &:empty {
        margin-bottom:0px;
    }
}

.display-block {
    display:block;
}

.display-inline-block {
    display:inline-block;
}

.display-flex {
    display: flex;
}

.display-inline-flex {
    display: inline-flex;
}

.display-grid {
    display: grid;
}

.display-inline-grid {
    display: inline-grid;
}

.display-none, .hidden, .d-none {
    display:none;
}

.display-table {
    display:table;
}

.display-table-row {
    display:table-row;
}

.display-table-cell {
    display:table-cell;
}

.flex-wrap {
    flex-wrap: wrap;
}

.direction-column {
    flex-direction: column;
}

.justify-center {
    justify-content: center;
}

.justify-space-between {
    justify-content: space-between;
}

.justify-space-evenly {
    justify-content: space-evenly;
}

.justify-flex-start {
    justify-content: flex-start;
}

.justify-flex-end {
    justify-content: flex-end;
}

.align-items-center {
    align-items: center;
}

.align-items-start {
    align-items: flex-start;
}

.align-items-end {
    align-items: flex-end;
}

.flex-direction-column {
    flex-direction:column;
}

.flex-direction-row-reverse {
    flex-direction: row-reverse;
}

.text-align-center {
    text-align: center;
}

.text-align-right {
    text-align: right;
}

.zero-fsize {
    font-size:0px;
}

.zero-lheight {
    line-height:0px;
}

.zero-text {
    font-size:0px;
    line-height:0px;
}

.primary-text {
    @include primaryText();
}

.primary-fsize {
    font-size:$primary-fsize;
}

.primary-lheight {
    line-height:$primary-lheight;
}

.secondary-text {
    @include secondaryText();
}

.secondary-fsize {
    font-size:$secondary-fsize;
}

.secondary-lheight {
    line-height:$secondary-lheight;
}

.tertiary-text {
    @include tertiaryText();
}

.tertiary-fsize {
    font-size:$tertiary-fsize;
}

.tertiary-lheight {
    line-height:$tertiary-mobile-lheight;
}

.lead-text {
    @include leadText();
}

.lead-fsize {
    font-size:$lead-fsize;
}

.lead-lheight {
    font-size:$lead-lheight;
}

.position-relative {
    position:relative;
}

.position-absolute {
    position:absolute;
}

.absolute-center {
    top:0px;
    left:0px;
    right:0px;
    bottom:0px;
    margin:auto;
}

.color-transition {
    transition:$color-transition;
}

.bg-transition {
    transition:$bg-color-transition;
}

.transform-transition {
    transition:$transform-transition;
}

.full-width {
    width:100%;
}

.normal-fweight {
    font-weight:$normal-fweight;
}

.semi-fweight {
    font-weight: $semi-fweight;
}

b, strong, .bold-fweight, .bold {
    font-weight:$bold-fweight;
}


ul, ol {
    list-style:none;
}

.clear {
    clear: both;
    width:100%;
    display:block;
    height:0px;
}

.float-left {
    float:left;
}

.float-right {
    float:right;
}

.text-lowercase {
    text-transform: lowercase;
}

.text-uppercase {
    text-transform: uppercase;
}

.text-underline {
    text-decoration: underline;
}

.bg-image-cover {
    background-repeat:none;
    background-position:center center;
    background-size:cover;
}

.bg-position-y-top {
    background-position-y:top;
}

.bg-position-y-center {
    background-position-y:center;
}

.bg-position-y-bottom {
    background-position-y:bottom;
}

.bg-position-x-left {
    background-position-x:left;
}

.bg-position-x-center {
    background-position-x:center;
}

.bg-position-x-right {
    background-position-x:right;
}

.bg-white {
    background:$white;
}

.color-white {
    color:$white;
}

.color-black {
    color:$black;
}

.color-inherit {
    color:inherit;
}

.bg-black {
    background:$black;
}

.pointer, .cursor-pointer {
    cursor:pointer;
}

.without-underline {
    text-decoration:none;
}

img {
    display:block;
    max-width:100%;
    max-height:100%;

    &.lazy {
        &:not(.loaded) {
            opacity:0;
        }
    }
}

video {
    max-width:100%;
    max-height:100%;
    display:block;
}

.space-nowrap {
    white-space: nowrap;
}

.overflow-hidden {
    overflow:hidden;
}

.circle {
    border-radius:50%;
}

.content-width {
    width: 100%;
    max-width: $content-width;
    box-sizing: border-box;
    margin: auto;
    position: relative;
    padding: 0px $container-side-padding 0px $container-side-padding;

    &:not(.display-flex):not(.display-grid) {
        display: block;
    }

    &.thin-content {
        max-width: map-get($contentWidthSizes, "thin");
    }

    &.wide-content {
        max-width: map-get($contentWidthSizes, "wide");
    }

    &.without-padding {
        padding-left: 0px;
        padding-right: 0px;
        max-width:#{map-get($contentWidthSizes, "normal") - ($container-side-padding * 2)};

        &.thin-content {
            max-width:#{map-get($contentWidthSizes, "thin") - ($container-side-padding * 2)};
        }

        &.wide-content {
            max-width:#{map-get($contentWidthSizes, "wide") - ($container-side-padding * 2)};
        }
    }
}

body, html {
    &.scroll-disabled {
        overflow:hidden;
    }
}

h1, .like-h1, 
h2, .like-h2, 
h3, .like-h3,
h4, .like-h4 {
    font-weight:$default-heading-fweight;
    color:$default-heading-color;
    margin:0px;
}

h4, .like-h4 {
    @include headerStyle(desktop, h4);
}

h3, .like-h3 {
    @include headerStyle(desktop, h3);
}

h2, .like-h2 {
    @include headerStyle(desktop, h2);
}

h1, .like-h1 {
    @include headerStyle(desktop, h1);
}

.margin-top-zero {
    margin-top:map-get($margins, "zero");
}

.margin-top-mini {
    margin-top:map-get($margins, "mini");
}

.margin-top-small {
    margin-top:map-get($margins, "small");
}

.margin-top-normal {
    margin-top:map-get($margins, "normal");
}

.margin-top-big {
    margin-top:map-get($margins, "big");
}

.margin-bottom-zero {
    margin-bottom:map-get($margins, "zero");
}

.margin-bottom-mini {
    margin-bottom:map-get($margins, "mini");
}

.margin-bottom-small {
    margin-bottom:map-get($margins, "small");
}

.margin-bottom-normal {
    margin-bottom:map-get($margins, "normal");
}

.margin-bottom-big {
    margin-bottom:map-get($margins, "big");
}

.margin-auto {
    margin:auto;
}

.hidden-mail {
    &:before {
        content:attr(data-user);
    }

    &:after {
        content:attr(data-domain);
    }
}

@include hoverStatements {
    a {
        &:hover {
            color:$primary-anchor-hover-color;
            text-decoration:$anchor-hover-text-decoration;

            &.secondary {
                color:$secondary-anchor-hover-color;
            }
        }

    }
}

@include lowerBreakpoint(601px) {
    .show-from-mobile {
        display:none!important;
    }
}

@include lying-tablet-breakpoint {
    .content-width {
        padding-left:map-get($sidePaddings, "tablet");
        padding-right:map-get($sidePaddings, "tablet");

        &.thin-content {
            max-width: map-get($contentWidthSizes, "tabletThin");
        }
    }
}

@include below-lying-tablet {
    .margin-top-mini {
        margin-top:map-get($margins, "tabletMini");
    }

    .margin-top-small {
        margin-top:map-get($margins, "tabletSmall");
    }
    
    .margin-top-normal {
        margin-top:map-get($margins, "tabletNormal");
    }
    
    .margin-top-big {
        margin-top:map-get($margins, "tabletBig");
    }

    .margin-bottom-mini {
        margin-bottom:map-get($margins, "tabletMini");
    }
    
    .margin-bottom-small {
        margin-bottom:map-get($margins, "tabletSmall");
    }
    
    .margin-bottom-normal {
        margin-bottom:map-get($margins, "tabletNormal");
    }
    
    .margin-bottom-big {
        margin-bottom:map-get($margins, "tabletBig");
    }
}

@include mobile {
    body {
        @include textStyle(primary, mobile);
    }
    
    .content-width {
        padding-left:map-get($sidePaddings, "mobile");
        padding-right:map-get($sidePaddings, "mobile");
    }

    h4, .like-h4 {
        @include headerStyle(mobile, h4);
    }
    
    h3, .like-h3 {
        @include headerStyle(mobile, h3);
    }
    
    h2, .like-h2 {
        @include headerStyle(mobile, h2);
    }
    
    h1, .like-h1 {
        @include headerStyle(mobile, h1);
    }

    .primary-text {
        @include textStyle(primary, mobile);
    }

    .primary-fsize {
        font-size:$primary-mobile-fsize;
    }

    .primary-lheight {
        line-height:$primary-mobile-lheight;
    }

    .secondary-text {
        @include textStyle(secondary, mobile);
    }

    .secondary-fsize {
        font-size:$secondary-mobile-fsize;
    }

    .secondary-lheight {
        line-height:$secondary-mobile-lheight;
    }

    .tertiary-text {
        @include textStyle(tertiary, mobile);
    }

    .tertiary-fsize {
        font-size:$tertiary-mobile-fsize;
    }

    .tertiary-lheight {
        line-height:$tertiary-mobile-lheight;
    }

    .lead-text {
        @include textStyle(lead, mobile);
    }

    .lead-fsize {
        font-size:$lead-mobile-fsize;
    }

    .lead-lheight {
        line-height:$lead-mobile-lheight;
    }

    .margin-top-mini {
        margin-top:map-get($margins, "mobileMini");
    }
    
    .margin-top-small {
        margin-top:map-get($margins, "mobileSmall");
    }
    
    .margin-top-normal {
        margin-top:map-get($margins, "mobileNormal");
    }
    
    .margin-top-big {
        margin-top:map-get($margins, "mobileBig");
    }

    .margin-bottom-mini {
        margin-bottom:map-get($margins, "mobileMini");
    }
    
    .margin-bottom-small {
        margin-bottom:map-get($margins, "mobileSmall");
    }
    
    .margin-bottom-normal {
        margin-bottom:map-get($margins, "mobileNormal");
    }
    
    .margin-bottom-big {
        margin-bottom:map-get($margins, "mobileBig");
    }

    .hide-on-mobile {
        display:none!important;
    }
}