@mixin transform($transform...) {
    -webkit-transform: $transform;
    transform: $transform;
}

@mixin breakpoint($width) {
    @media screen and (max-width: #{$width}) {
        @content;
    }
}

@mixin lowerBreakpoint($width) {
    @media screen and (min-width: #{$width}) {
        @content;
    }
}

@mixin betweenBreakpoint($startWidth, $endWidth) {
    @media screen and (min-width: #{$startWidth}) and (max-width: #{$endWidth}) {
        @content;
    }
}

@mixin lying-tablet-breakpoint {
    @include breakpoint(map-get($breakpoints, "lyingTablet")) {
        @content;
    }
}

@mixin below-lying-tablet {
    @include breakpoint(#{map-get($breakpoints, "lyingTablet") - 1px}) {
        @content;
    }
}

@mixin standing-tablet-breakpoint {
    @include breakpoint(map-get($breakpoints, "standingTablet")) {
        @content;
    }
}

@mixin below-standing-tablet {
    @include breakpoint(#{map-get($breakpoints, "standingTablet") - 1px}) {
        @content;
    }
}

@mixin mobile {
    @include breakpoint(map-get($breakpoints, "mobile")) {
        @content;
    }
}

@mixin hoverStatements($content...) {
    @media all and (min--moz-device-pixel-ratio:0) {@content;}
    @media (hover:hover) {@content;}
    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {@content;}
}

@mixin hoverStatementsHard($content...) {
    @media (pointer:fine) {@content;}
}

@mixin showMobileMenu($content...) {
    @media (pointer: coarse) {
        @content;
    }

    @media screen and (max-width:$mobile-menu-breakpoint) {
        @content;
    }
}

@mixin touchDevice($content...) {
    @media (pointer:coarse) {
        @content;
    }
}

@mixin placeholder {
    ::-webkit-input-placeholder {
        @content;
    }

    :-moz-placeholder {
        @content;
    }

    ::-moz-placeholder {
        @content;
    }

    :-ms-input-placeholder {
        @content;
    }
}


@mixin resolution2X {
    @media only screen and (-webkit-min-device-pixel-ratio: 2),
        only screen and (min--moz-device-pixel-ratio: 2),
        only screen and (-o-min-device-pixel-ratio: 2/1),
        only screen and (min-device-pixel-ratio: 2),
        only screen and (min-resolution: 192dpi),
        only screen and (min-resolution: 2dppx) {
        @content;
    }
}

@mixin resolution3X {
    @media only screen and (-o-min-device-pixel-ratio: 9/4),
        only screen and (-webkit-min-device-pixel-ratio: 2.25),
        only screen and (min-device-pixel-ratio: 2.25),
        only screen and (min-resolution: 2.25dppx) {
        @content;
    }
}

@mixin forFirefox($content...) {
    @media all and (min--moz-device-pixel-ratio:0) {@content;}
}

@mixin showMobileFilter($content...) {
    @media (pointer: coarse) and (max-width:1280px) {
        @content;
    }

    @media screen and (max-width:1024px) {
        @content;
    }
}