footer {
    color:$white;
    background:$blue;

    a {
        color:inherit;
        text-decoration:none;
    }

    .top-content {
        padding:19px 0px 19px 0px;
        background-color:#0084D4;
        background-image: linear-gradient(110deg, #0084D4 50.2%, #029CE0 850%);
    }

    .footer-contact-item {
        position:relative;
        display:flex;
        align-items:center;

        &:after {
            content:'';
            width:1px;
            height:24px;
            min-width:1px;
            background:$white;
            margin:0px 40px 0px 40px;
        }

        &:last-of-type {
            &:after {
                display:none;
            }
        }
    }

    .footer-contact-link {
        img {
            margin-right:9px;
        }

        .text {
            @include leadText();
            font-weight:$semi-fweight;
        }
    }

    .social-item {
        color:$white;
        margin-right:16px;

        &:last-of-type {
            margin-right:0px;
        }
    }

    .middle-content {
        padding:50px 0px 50px 0px;

        .inner-content {
            gap:30px;
        }

        .title-row {
            margin-bottom:18px;
            min-height:44px;

            .icon {
                display:none;
            }
        }

        .row {
            margin-bottom:10px;
            line-height:18px;

            &:last-of-type {
                margin-bottom:0px;
            }
        }
    }

    .bottom-content {
        padding:12px 0px 12px 0px;

        .left-content {
            .logo-content {
                width:75px;
                margin-right:25px;
            }
        }
    }

    .pwstudio-link {
        .text {
            font-size:12px;
            line-height:14px;
            letter-spacing: $secondary-lspacing;
        }

        .icon {
            min-width:16px;
            margin-left:5px;
        }
    }
}

@include hoverStatements {
    footer {
        a {
            &:hover {
                color:$white;
                text-decoration:underline;
            }
        }

        .pwstudio-link, .footer-contact-link {
            &:hover {
                text-decoration: none;

                .text {
                    text-decoration: underline;
                }
            }
        }
    }
}

@include below-lying-tablet {
    footer {
        .footer-contact-item {
            &:after {
                margin:0px 20px 0px 20px;
            }
        }
        .footer-contact-link {
            .text {
                font-size:14px;
                line-height:20px;
            }
        }

        .middle-content {
            padding:0px 0px 30px 0px;

            > .inner-content {
                display:block;
            }
        }

        .accordion-item {
            border-bottom:1px solid $white;

            .title-row {
                display:flex;
                align-items:center;
                justify-content:space-between;
                margin-bottom:0px;
                padding:18px 10px 18px 10px;
                cursor:pointer;

                .icon {
                    display:block;
                    width:8px;
                    min-width:8px;
                    height:5px;
                }

                .title {
                    padding-right:10px;
                }
            }

            .list-content {
                display:none;

                .list-inner {
                    padding:3px 10px 25px 10px;
                    column-count:3;
                    column-gap:30px;
                    width:100%;

                    .row {
                        break-inside: avoid;
                    }
                }
            }
        }
    }
}

@include below-standing-tablet {
    footer {
        .accordion-item {
            .list-content {
                .list-inner {
                    column-count:2;
                }
            }
        }

        .top-content {
            background:$blue;
            padding:40px 0px 40px 0px;

            > .inner-content {
                display:block;
            }
        }

        .footer-contact-item {
            margin:0px 0px 14px 0px;
            
            &:last-of-type {
                margin-bottom:0px;
            }

            &:after {
                display:none;
            }

            &.social-items {
                margin-top:30px;
            }
        }

        .accordion-item {
            &:first-of-type {
                border-top:1px solid $white;
            }
        }
    }
}

@include mobile {
    footer {
        .accordion-item {
            .title-row {
                .title {
                    font-size:16px;
                    line-height:18px;
                    font-weight:$bold-fweight;
                }
            }

            .list-content {
                .list-inner {
                    column-count:1;
                }

                .row {
                    font-size:14px;
                }
            }
        }

        .middle-content {
            padding-bottom:40px;
        }

        .bottom-content {
            padding-top:0px;
            
            .inner-content {
                align-items:flex-end;
            }

            .left-content {
                flex-direction: column;
                justify-content:flex-start;
                align-items: flex-start;
                
                .logo-content {
                    width:66px;
                    margin:0px 0px 6px 0px;
                }

                .copyright {
                    font-weight:$semi-fweight;
                }
            }

            .pwstudio-link {
                .text {
                    text-align:right;
                    span {
                        display:block;
                    }
                }
            }
        }
    }
}