@import './core/all.scss';
@import './core/defaults.scss';
@import './header.scss';
@import './footer.scss';
@import './sprite.scss';
@import './form-elements.scss';
@import './package-calculator-section.scss';
@import './selectric-customize.scss';
@import './mobile-menu.scss';
@import './remodal-customize.scss';
@import './notyf-customize.scss';

.main-content {
    padding-top:40px;
    padding-bottom:110px;
}

.package-send-info-seciton {
    padding:50px 0px 40px 0px;

    .content-width {
        column-count:2;
        column-gap:60px;
    }

    p {
        margin-bottom:6px;

        &:last-of-type {
            margin-bottom:0px;
        }
    }
}

.tracking-button {
    .icon {
        margin-right:5px;
    }

    .text {
        transform:translateY(1px);
    }
}

.plus-icon {
    display:flex;
    justify-content:center;
    align-items:center;
    position:relative;
    background:$blue;
    width:18px;
    height:18px;
    transition:$bg-color-transition;
    border-radius:50%;

    &:before {
        content:'';
        display:block;
        width:8px;
        height:2px;
        background:$white;
        transition:$bg-color-transition;
        border-radius:2px;
    }

    &:after {
        content:'';
        display:block;
        width:2px;
        height:8px;
        border-radius:2px;
        background:$white;
        transition:$bg-color-transition;
        position:absolute;
        top:0px;
        left:0px;
        right:0px;
        bottom:0px;
        margin:auto;
    }
}

blockquote {
    padding:20px 20px 20px 50px;
    background:$light-blue;
    position:relative;
    font-size:16px;
    line-height:22px;
    border-radius:2px;
    background:url('../img/svg/icons/info.svg') no-repeat $light-blue top 15px left 15px / 26px 27px;

    &.red {
        background:url('../img/svg/icons/warning.svg') no-repeat $lighter-red top 15px left 15px / 26px 28px;
    }

    &.without-icon {
        padding-left:20px;
    }
}

.accordion-item {
    .accordion-title {
        .icon {
            transition:$transform-transition;
        }
    }

    &.active {
        > .accordion-title {
            .icon {
                transform:rotate(180deg);
            }
        }
    }
}

.password-field-outer {
    position:relative;

    .field {
        padding-right:44px;
    }

    .password-show-outer {
        position:absolute;
        transition:$color-transition;
        cursor: pointer;;
        top:0px;
        right:12px;
        bottom:0px;
        margin:auto;
        width:22px;
        height:15px;
        
        &.active {
            color:$blue;
        }
    }
}

.form-content {
    .city-col {
        display:flex;
        justify-content:space-between;

        .zip {
            width:108px;
        }

        .city {
            width:calc(100% - 128px);
        }
    }

    .more-col-row {
        .street-col, .number-col {
            &:not(.full-width) {
                width:calc((50% - 30px) / 2);
            }
        }
    }
}

.close-btn {
    position: absolute;
    width:22px;
    height:22px;
    padding:5px;
    color:$primary-color;
    opacity:0.6;
    transition:$opacity-transition;
    cursor:pointer;

    .icon {
        display: block;
        width: 100%;
        height: 100%;
    }
}

@include hoverStatements {
    a {
        &:hover {
            &.without-underline {
                text-decoration:underline;
            }
        }
    }

    .close-btn {
        &:hover {
            opacity:1;
        }
    }
}

@include below-lying-tablet {
    .package-send-info-seciton {
        .content-width {
            column-gap:40px;
        }
    }

    .main-content {
        padding-top:20px;
        padding-bottom:80px;
    }
}

@include below-standing-tablet {
    .form-content {
        .more-col-row {
            .street-col, .number-col {
                &:not(.full-width) {
                    width:calc((100% - #{$form-column-gap}) / 2);
                }
            }
        }
    }
}

@include mobile {
    .package-send-info-seciton {
        padding:25px 0px 15px 0px;
        
        .content-width {
            column-count:1;
        }
    }

    .main-content {
        padding-bottom:60px;
        padding-top:0px;
    }

    .form-content {
        .city-col {
            .city {
                width:calc(100% - 123px);
            }
        }

        .more-col-row {
            .street-col, .number-col {
                &:not(.full-width) {
                    width:100%;
                }
            }
        }
    }
}