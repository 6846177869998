@import './inc/style.scss';

.page-not-found-content {
    img {
        margin:0px auto;
    }

    h1 {
        color:$blue;
        font-size:78px;
        line-height:78px;
        margin:25px auto 10px;
    }

    .back-link-row {
        margin-top:85px;
    }

    .back-link {
        .icon {
            margin-right:4px;
        }
    }
}

@include mobile {
    .page-not-found-content {
        h1 {
            font-size:50px;
            line-height:50px;
            margin:20px auto 10px;
        }
    
        .back-link-row {
            margin-top:40px;
        }
    
        .back-link {
            .icon {
                margin-right:4px;
            }
        }
    }
}