// @use "sass:map";

/** töréspontok */
$lying-tablet-width: 1024px;
$standing-tablet-width: 768px;
$mobile-breakpoint: 600px;
$mobile-menu-breakpoint: 1180px;

$breakpoints: (
  "lyingTablet": $lying-tablet-width,
  "standingTablet": $standing-tablet-width,
  "mobile": $mobile-breakpoint
);

/** Content szélesség */
$thin-content: 840px;
$content-width: 1200px;
$wide-content: 1300px;

$contentWidthSizes: (
  "thin": $thin-content,
  "normal": $content-width,
  "wide": $wide-content,
  "tabletThin": 800px   
);

$margins: (
    "zero":0px,
    "mini":15px,
    "small":30px,
    "normal":45px,
    "big":70px,
    "tabletMini":15px,
    "tabletSmall":30px,
    "tabletNormal":45px,
    "tabletBig":60px,
    "mobileMini":15px,
    "mobileSmall":20px,
    "mobileNormal":20px,
    "mobileBig":30px
);

$sidePaddings: (
    "zero":0px,
    "mobile":15px,
    "tablet":30px,
    "desktop":50px
);

$container-side-padding: map-get($sidePaddings, "desktop");

/** Betű méretek */
$primary-lspacing:0.2px;
$secondary-lspacing:0.4px;

$primary-font-family: 'Catamaran', Arial, sans-serif;
$primary-fsize:16px;
$primary-lheight:22px;
$primary-mobile-fsize: 14px;
$primary-mobile-lheight: 20px;

$secondary-fsize:14px;
$secondary-lheight:20px;
$secondary-mobile-fsize: 12px;
$secondary-mobile-lheight: 16px;

$tertiary-fsize:12px;
$tertiary-lheight:16px;
$tertiary-mobile-fsize:12px;
$tertiary-mobile-lheight:16px;

$lead-fsize:18px;
$lead-lheight:26px;
$lead-lspacing:$secondary-lspacing;
$lead-mobile-fsize:16px;
$lead-mobile-lheight:24px;


$normal-fweight:400;
$medium-fweight:500;
$semi-fweight:600;
$bold-fweight:700;

$paragraph-bottom-margin: 22px;

/* Header elemek betű méretei */
$default-heading-fweight: $normal-fweight;
$default-heading-lspacing: $secondary-lspacing;

$h1-fsize: 26px;
$h1-lheight: 32px;
$h1-fweight: $bold-fweight;
$h1-mobile-fsize: 20px;
$h1-mobile-lheight: 26px;
$h1-lspacing: $default-heading-lspacing;

$h1-big-fsize: 36px;
$h1-big-lheight: 40px;
$h1-big-mobile-fsize: 22px;
$h1-big-mobile-lheight: 26px;

$h2-fsize: 20px;
$h2-lheight: 24px;
$h2-fweight: $bold-fweight;
$h2-mobile-fsize: 18px;
$h2-mobile-lheight: 22px;
$h2-lspacing: $default-heading-lspacing;

$h3-fsize: 18px;
$h3-lheight: 22px;
$h3-fweight:$bold-fweight;
$h3-mobile-fsize: 16px;
$h3-mobile-lheight: 20px;
$h3-lspacing: $default-heading-lspacing;

$h4-fsize: 16px;
$h4-lheight: 20px;
$h4-fweight:$bold-fweight;
$h4-mobile-fsize: 14px;
$h4-mobile-lheight: 18px;
$h4-lspacing: $default-heading-lspacing;

/** notyf */
$notyf-fsize: 14px;
$notyf-lheight: 20px;
$notyf-lspacing: 0.2px;
$notyf-width: 280px;
$notyf-right: 30px;
$notyf-bottom: 30px;
$notyf-font-style: normal;
$notyf-padding: 19px 19px 19px 19px;
$notyf-icon-width: 34px;

$notyf-mobile-fsize: 14px;
$notyf-mobile-lheight: 20px;
$notyf-mobile-lspacing: 0px;
$notyf-mobile-width: 100%;
$notyf-mobile-right: 0px;
$notyf-mobile-bottom: 10px;


/** Transition */
$transition-duration:0.25s;
$transition-timing:ease;

$transform-transition: transform $transition-duration $transition-timing;
$bg-color-transition: background-color $transition-duration $transition-timing;
$border-color-transition: border-color $transition-duration $transition-timing;
$border-width-transtion: border-width $transition-duration $transition-timing;
$opacity-transition: opacity $transition-duration $transition-timing;
$color-transition: color $transition-duration $transition-timing;
$box-shadow-transition: box-shadow $transition-duration $transition-timing;
$padding-transition: padding $transition-duration $transition-timing;
$height-transition: height $transition-duration $transition-timing;
$all-transition: all $transition-duration $transition-timing;

/** Form */
$form-column-gap: 20px;
$form-row-gap: 20px;
$form-mobile-row-gap: 15px;
$field-label-fsize:14px;
$field-label-lheight:20px;
$field-label-lspacing:$secondary-lspacing;
$field-label-fweight:$semi-fweight;
$field-label-mbottom:1px;

/** Input mezők */
$input-height: 48px;
$input-border-width: 1px;
$input-border-style: solid;
$input-border-radius: 2px;
$input-padding: 10px 12px 10px 12px;
$input-font-family: $primary-font-family;
$input-fsize:16px;
$input-lheight:20px;
$input-lspacing:0.2px;
$input-font-style:normal;
$input-fweight:$normal-fweight;
$input-text-transform: none;

/** select */
$select-padding: 0px 35px 0px 15px;

/** checkbox */
$checkbox-dimension-size:20px;
$checkbox-side-padding:6px;
$checkbox-fsize:14px;
$checkbox-lheight:20px;
$checkbox-label-transition:$color-transition;
$checkbox-border-width:1px;
$checkbox-border-style:solid;
$checkbox-border-radius: 2px;
$checkbox-before-top-position:-1px;
$checkbox-before-left-position:0px;
$checkbox-before-transition:$bg-color-transition;
$checkbox-after-top-position:-1px;
$checkbox-after-left-position:0px;
$checkbox-after-opacity:0;
$checkbox-after-checked-opacity:1;
$checkbox-after-transition:$opacity-transition;

$checkbox-small-dimension-size: 12px;
$checkbox-small-side-padding: 7px;
$checkbox-small-fsize: 12px;
$checkbox-small-lheight: 20px;

$checkbox-small-before-top-position:4px;
$checkbox-small-before-left-position:0px;
$checkbox-small-after-top-position:4px;
$checkbox-small-after-left-position:0px;

/** radio */
$radio-dimenson-size: 20px;
$radio-side-padding: 8px;
$radio-fsize: 16px;
$radio-lheight: 20px;
$radio-transition:none;
$radio-border-width: 1px;
$radio-border-style: solid;
$radio-border-radius: 50%;
$radio-before-top-position: -1px;
$radio-before-left-position:0px;
$radio-before-transition: $bg-color-transition;
$radio-after-top-position: 4px;
$radio-after-left-position: 5px;
$radio-after-transition: $opacity-transition;
$radio-after-opacity:0;
$radio-after-checked-opacity:1;
$radio-after-dimension-size: 10px;

/** Gomb */
$button-font-family:$primary-font-family;
$button-height: 48px;
$button-fsize: 18px;
$button-lheight: 20px;
$button-fweight: $bold-fweight;
$button-lspacing: 0.2px;
$button-padding: 9px 18px 7px 18px;
$button-border-radius: 2px;
$button-text-transform: none;
$button-font-style: normal;
$button-text-decoration: none;

$button-border-width: 1px;
$button-border-style: solid;

$button-mobile-height: 48px;
$button-mobile-fsize: 18px;
$button-mobile-lheight: 20px;
$button-mobile-lspacing: 0.2px;

$button-small-height: 40px;
$button-small-fsize: 14px;
$button-small-lheight: 18px;
$button-small-padding: 9px 18px 7px 18px;

$button-mini-height:26px;
$button-mini-fsize:12px;
$button-mini-lheight:12px;
$button-mini-padding:5px 10px 3px 10px;
$button-mini-text-transform: uppercase;

/** Form hiba mező */
$form-error-content-fsize:12px;
$form-error-content-lheight:16px;
$form-error-content-fweight: $semi-fweight;
$form-error-content-mtop:2px;
$form-error-content-lspacing:$primary-lspacing;

/** Mobil menü */
$mobile-menu-width: 360px;

/** Egyéb */
$border-radius:0px;

/** Linkek */
$anchor-fweight: $semi-fweight;
$anchor-default-text-decoration: underline;
$anchor-hover-text-decoration: none;