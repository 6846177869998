$white: #ffffff;
$black: #393939;

$light-red: #FFECEC;
$lighter-red: #FFDBDB;
$red: #EC2424;

$light-green: #DBFFDF;
$green: #64BC40;

$light-gray: #F7F7F7;
$lighter-gray: #f0f0f0;
$gray: #C0C4CB;
$dark-gray:#757982;

$beige: #EBE4E2;

$extra-light-blue: #F7FCFF;
$light-blue: #D3EAF5;
$lighter-blue: #BEE3F4;
$blue: #029CE0;

$purple: #441196;

$orange: #F46939;

$error-color: $red;


$primary-color: $black;
$default-heading-color: $primary-color;


/* Link szín */
$primary-anchor-color: $blue;
$primary-anchor-hover-color: $blue;

$secondary-anchor-color: $primary-color;
$secondary-anchor-hover-color: $primary-color;

/* Gombok színei */
$primary-button-bg: $blue;
$primary-button-color:$white;
$primary-button-border-color:$blue;
$primary-button-hover-bg: $white;
$primary-button-hover-color:$blue;
$primary-button-hover-border-color: $blue;

$secondary-button-bg: $orange;
$secondary-button-color: $white;
$secondary-button-border-color: $orange;
$secondary-button-hover-bg: $white;
$secondary-button-hover-color: $orange;
$secondary-button-hover-border-color:$orange;


/* Input mezők színei */
$input-bg: $white;
$input-color:$primary-color;
$input-border-color: $gray;
$input-placeholder-color: rgba(71, 71, 71, 0.8);

$input-secondary-border-color: $gray;

$input-error-color: $red;
$input-error-bg: $light-red;
$input-error-border-color: $red;

$input-focus-color: $primary-color;
$input-focus-bg: $white;
$input-focus-border-color: $primary-color;

/* Checkbox színek */
$checkbox-bg: $white;
$checkbox-border-color:$gray;
$checkbox-secondary-border-color:$blue;
$checkbox-error-bg: $light-red;
$checkbox-color:$primary-color;
$checkbox-anchor-color:$blue;
$checkbox-anchor-hover-color:$blue;
$checkbox-checked-anchor-color:$blue;
$checkbox-checked-color: $primary-color;
$checkbox-checked-bg: url('../img/svg/icons/check.svg') no-repeat $orange center center / 10px 8px;
$checkbox-small-checked-bg: url('../img/svg/icons/check.svg') no-repeat $orange center center / 10px 8px;

$checkbox-secondary-checked-bg: url('../img/svg/icons/check.svg') no-repeat $blue center center / 10px 8px;
$checkbox-secondary-small-checked-bg: url('../img/svg/icons/check.svg') no-repeat $blue center center / 10px 8px;

/** */
$radio-color:$primary-color;
$radio-checked-color:$primary-color;
$radio-before-border-color:$gray;
$radio-before-bg: $white;
$radio-checked-before-border-color: $orange;
$radio-checked-before-bg: $white;
$radio-error-before-bg:$light-red;
$radio-error-before-border-color: $red;
$radio-after-bg: $orange;
$radio-checked-after-bg: $orange;


/** select */
$select-bg: url('../img/svg/icons/select-arrow.svg') no-repeat $white top 16px right 11px / 10px 6px;

/** notyf */
$notyf-error-color: $primary-color;
$notyf-error-bg: $white;
$notyf-error-border-color: $red;

$notyf-success-color: $primary-color;
$notyf-success-bg: $light-green;
$notyf-success-border-color: $green;

/** Mobil menü */
$mobile-menu-bg: $white;
$mobile-menu-primary-color: $primary-color;
$mobile-menu-secondary-color: $blue;

$mobile-menu-border-color: $beige;

