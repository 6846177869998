@import './calculator-container.scss';

.package-calculator-section {
    position:relative;
    padding:55px 0px 70px 0px;

    > .content-width {
        position:static;
    }

    .background-item {
        position:absolute;
        top:0px;
        left:0px;
        right:0px;
        bottom:0px;
        width:100%;
        height:100%;
        display:block;
        z-index:1;

        img {
            object-fit: cover;
            object-position: top center;
            width:100%;
            height:100%;
        }
    }

    .top-text-content {
        color:$white;

        > * {
            &:not(.background-item) {
                position:relative;
                z-index:2;
            }
        }

        .compare-info-text {
            display:none;
        }
    }

    h1, .like-h1 {
        font-size:$h1-big-fsize;
        line-height:$h1-big-lheight;
        color:$white;
        max-width:670px;
        margin-left:auto;
        margin-right:auto;
    }

    .lead {
        margin-top:10px;
        font-size:24px;
        line-height:24px;
        letter-spacing: $secondary-lspacing;
    }

    .calculator-container {
        position:relative;
        z-index:2;
        margin:40px auto 0px auto;
        max-width:910px;
        
        .calculator-content {
            background:rgba(255, 255, 255, 0.86);
        }

        .calculator-inner {
            max-width:740px;
        }
    }

    .compare-info-text {
        position:relative;
        z-index:2;
        margin:30px auto 0px auto;
        color:$white;
        background-color:rgba(255, 255, 255, 0.2);
        padding:12px 30px 12px 30px;
        border-radius:80px;
        
        .icon {
            margin-right:5px;
        }

        .text {
            font-size:20px;
            line-height:26px;
            font-weight:$bold-fweight;
            letter-spacing: $secondary-lspacing;
            transform:translateY(1px);
        }
    }
}

@include below-standing-tablet {
    .package-calculator-section {
        padding:0px;
        box-shadow: 0 1px 25px -13px rgba(43,60,68,0.60);

        .content-width {
            padding:0px;

            > *:not(.calculator-container) {
                padding-left:map-get($sidePaddings, "tablet");
                padding-right:map-get($sidePaddings, "tablet");
            }

            > .compare-info-text {
                display:none;
            }
        }

        .top-text-content {
            position:relative;
            padding:40px map-get($sidePaddings, "tablet") 30px map-get($sidePaddings, "tablet");

            .compare-info-text {
                display:inline-flex;
            }

            .background-item {
                height:calc(100% + 61px);
            }
        }

        .calculator-container {
            margin-top:0px;

            .calculator-tabs {
                .calculator-tab-item {
                    &.active {
                        .inner {
                            background:$light-gray;
                        }
                    }
                }
            }
            
            .calculator-content {
                background:$light-gray;
            }
        }
    }
}

@include mobile {
    .package-calculator-section {
        .top-text-content {
            padding:40px map-get($sidePaddings, "mobile") 30px map-get($sidePaddings, "mobile");

            .background-item {
                height:calc(100% + 52px);
            }
        }

        h1, .like-h1 { 
            font-size:$h1-big-mobile-fsize;
            line-height:$h1-big-mobile-lheight;
        }

        .lead {
            font-size:18px;
            line-height:24px;
            margin-top:0px;
        }

        .compare-info-text {
            margin-top:24px;
            padding:6px 8px 6px 8px;

            .text {
                font-size:14px;
                line-height:20px;
            }
        }
    }
}