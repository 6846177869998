@mixin primaryText($type:desktop) {
    @if $type == desktop {
        font-size:$primary-fsize;
        line-height:$primary-lheight;
    } @else if $type == mobile {
        font-size:$primary-mobile-fsize;
        line-height:$primary-mobile-lheight; 
    }
}
 
@mixin secondaryText($type:desktop) {
    @if $type == desktop {
        font-size:$secondary-fsize;
        line-height:$secondary-lheight;
    } @else if $type == mobile {
        font-size:$secondary-mobile-fsize;
        line-height:$secondary-mobile-lheight;
    }
}

@mixin tertiaryText($type:desktop) {
    @if $type == desktop {
        font-size:$tertiary-fsize;
        line-height:$tertiary-lheight;
    } @else if $type == mobile {
        font-size:$tertiary-mobile-fsize;
        line-height:$tertiary-mobile-lheight;
    }
}

@mixin leadText($type:desktop) {
    @if $type == desktop {
        font-size:$lead-fsize;
        line-height:$lead-lheight;
        letter-spacing:$lead-lspacing;
    } @else if $type == mobile {
        font-size:$lead-mobile-fsize;
        line-height:$lead-mobile-lheight; 
    }
}

@mixin textStyle($type:primary, $screen: desktop) {
    @if $type == primary {
        @include primaryText($screen);
    } @else if $type == secondary {
        @include secondaryText($screen);
    } @else if $type == lead {
        @include leadText($screen);
    }
}

@mixin buttonTextStyle($screen: desktop, $type: primary) {
    @if $screen == desktop {
        font-family:$button-font-family;
        font-size:$button-fsize;
        line-height:$button-lheight;
        letter-spacing:$button-lspacing;
        text-transform: $button-text-transform;
        text-decoration: $button-text-decoration;
        font-style:$button-font-style;
        font-weight:$button-fweight;
    } @if $screen == mobile {
        font-size:$button-mobile-fsize;
        line-height:$button-mobile-lheight;
        letter-spacing: $button-mobile-lspacing;
    }
}

@mixin inputFontText($screen: desktop) {
    @if $screen == desktop {
        font-family:$input-font-family;
        font-size:$input-fsize;
        line-height:$input-lheight;
        letter-spacing:$input-lspacing;
        text-transform: $input-text-transform;
        font-style:$input-font-style;
        font-weight:$input-fweight;
    } @if $screen == mobile {
        font-size:$input-mobile-fsize;
        line-height:$input-mobile-lheight;
        letter-spacing: $input-mobile-lspacing;
    }
}

@mixin bodyFont {
    font-family:$primary-font-family;
    color:$primary-color;
    letter-spacing: $primary-lspacing;
    font-weight:$normal-fweight;
    @include textStyle(primary, desktop);
}


@mixin headerStyle($type: desktop, $element: h1) {
    @if $type == desktop {
        @if $element == h1 {
            font-size:$h1-fsize;
            line-height:$h1-lheight;
            font-weight:$h1-fweight;
            letter-spacing: $h1-lspacing;
        } @else if $element == h2 {
            font-size:$h2-fsize;
            line-height:$h2-lheight;
            font-weight:$h2-fweight;
            letter-spacing: $h3-lspacing;
        } @else if $element == h3 {
            font-size:$h3-fsize;
            line-height:$h3-lheight;
            font-weight:$h3-fweight;
            letter-spacing: $h3-lspacing;
        } @else if $element == h4 {
            font-size:$h4-fsize;
            line-height:$h4-lheight;
            font-weight:$h4-fweight;
            letter-spacing: $h4-lspacing;
        }
    } @else if $type == mobile {
        @if $element == h1 {
            font-size:$h1-mobile-fsize;
            line-height:$h1-mobile-lheight;
        } @else if $element == h2 {
            font-size:$h2-mobile-fsize;
            line-height:$h2-mobile-lheight; 
        } @else if $element == h3 {
            font-size:$h3-mobile-fsize;
            line-height:$h3-mobile-lheight;
        } @else if $element == h4 {
            font-size:$h4-mobile-fsize;
            line-height:$h4-mobile-lheight;
        }
    } 
}


